import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { getEmailTemplates } from "../../../../redux/actions/emailTemplateActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyEmailLinkData,
  addNewCompanyContact,
} from "../../../../redux/actions/companiesActions";
import { useFormik } from "formik";
import { sendCompanyEmailLink } from "../../../../redux/actions/emailSendingActions";
import CKEditor from "react-ckeditor-component";
import { Loader } from "../../../constants/loaders";
import Toast from "../../../constants/toast";
import axios from "axios";
import { baseURL } from "../../../Shared/baseURL";
import asterik from "../../../../assets/images/asterisk.png";
import { FormValidationErrors } from "../../../constants/errors";
import { useTranslation } from "react-i18next";

const SendCompanyLinkEmail = (props) => {
  const { fixNavbar } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const settingsSelector = useSelector((state) => state.settings);

  const RenderSendEmailEditor = () => {
    const initialValues = {
      auto_generated_url_id: "",
      from: "",
      company_contact_id: "",
      subject: "",
      files: [],
      body: "",
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [emailsTemplates, setEmailTemplates] = useState([]);
    const [templateData, setTemplateData] = useState({});
    const [body, setBody] = useState("");
    const [uploadFiles, setUploadFiles] = useState([]);
    const [companyContacts, setCompanyContacts] = useState([]);
    const [addNewContactModal, setAddNewContactModal] = useState(false);
    const [checkTo, setCheckTo] = useState(false);
    const [errors, setErrors] = useState({});

    const toggleAddNewContactModal = () => {
      setAddNewContactModal(!addNewContactModal);
    };

    const fetchEmailTemplate = async () => {
      const PER_PAGE_RECORDS = 100
      const response = await dispatch(getEmailTemplates(1, PER_PAGE_RECORDS));
      response.payload.data.map(async (item) => {
        if (item.template_key.includes("company_job")) {
          setEmailTemplates([item]);
          const response = await dispatch(getCompanyEmailLinkData(id, item.id));
          let templateResponse = response.payload.template;
          let autoGeneratedUrlId = response.payload.auto_generated_url_id;
          setTemplateData(templateResponse);
          setFormValues({
            ...formValues,
            auto_generated_url_id: autoGeneratedUrlId,
            subject: templateResponse.subject,
          });
          setBody(templateResponse.body);
        }
      });
    };

    const fetchCompanyContacts = () => {
      try {
        const token = localStorage.getItem("token");
        return axios
          .get(`${baseURL}api/companies/${id}/contacts`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => setCompanyContacts(response.data.data))
          .catch((error) => console.log(error));
      } catch (error) {
        throw error;
      }
    };

    useEffect(() => {
      fetchEmailTemplate();
    }, []);

    useEffect(() => {
      fetchCompanyContacts();
    }, []);

    const sendEmailSelector = useSelector((state) => state.sendEmail);
    const userEmailSelector = useSelector(
      (state) => state.users.user_profile.data.email
    );

    const onChange = (evt) => {
      let newContent = evt.editor.getData();
      setBody(newContent);
    };

    const formik = useFormik({
      initialValues: formValues,
      onSubmit: (values) => {
        let files = [];
        if (uploadFiles.length > 0) {
          for (let i = 0; i <= uploadFiles.length; i++) {
            files.push(uploadFiles[0][i]);
          }
        }

        formValues.from = userEmailSelector;
        formValues.files = files;
        formValues.body = body;

        const formData = new FormData();
        formData.append(
          "auto_generated_url_id",
          formValues.auto_generated_url_id
        );
        formData.append("from", formValues.from);
        formData.append("company_contact_id", formValues.company_contact_id);
        formData.append("subject", formValues.subject);
        if (files.length > 0) {
          for (const file of files) {
            formData.append("files[]", file);
          }
        }
        formData.append("body", formValues.body);

        if (formValues.company_contact_id === "") {
          setCheckTo(true);
          document.getElementById("company-contact-id").scrollIntoView();
          return false;
        } else {
          setCheckTo(false);
          dispatch(
            sendCompanyEmailLink(formData, id, history, location, "", setErrors)
          );
        }
      },
    });

    const handleToFieldChange = (e) => {
      let value = e.target.value;
      if (value === "+") {
        setAddNewContactModal(true);
      } else {
        setCheckTo(false);
        setFormValues({ ...formValues, company_contact_id: value });
      }
    };

    return (
      <>
        <RenderAddNewContactModal
          conpamyContactId={id}
          addNewContactModal={addNewContactModal}
          setAddNewContactModal={setAddNewContactModal}
          toggleAddNewContactModal={toggleAddNewContactModal}
          fetchCompanyContacts={fetchCompanyContacts}
        />

        {/*ERRORS*/}
        {Object.keys(errors).length !== 0 ? (
          <FormValidationErrors errors={errors} />
        ) : null}

        <Form onSubmit={formik.handleSubmit}>
          <fieldset disabled={sendEmailSelector.isLoading}>
            <div className={"d-flex flex-column mb-2"}>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Input
                      type={"text"}
                      placeholder={"FROM"}
                      id={"from"}
                      name={"from"}
                      className={`form-control`}
                      value={userEmailSelector}
                      disabled={true}
                      style={{
                        fontSize: `${
                          settingsSelector.FontSize === "Large"
                            ? "large"
                            : settingsSelector.FontSize === "Extra Large"
                            ? "x-large"
                            : "14px"
                        }`,
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <select
                      className={"custom-select"}
                      // onChange={(e) => handleChange(e)}
                      style={{
                        fontSize: `${
                          settingsSelector.FontSize === "Large"
                            ? "large"
                            : settingsSelector.FontSize === "Extra Large"
                            ? "x-large"
                            : "14px"
                        }`,
                      }}
                    >
                      {emailsTemplates.map((items, index) => (
                        <option key={index} value={items.id}>
                          {items.title}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <select
                  type={"text"}
                  placeholder={"Kellele"}
                  id={"company-contact-id"}
                  name={"company_contact_id"}
                  className={"custom-select mt-2"}
                  onChange={(e) => handleToFieldChange(e)}
                  required={true}
                  style={{
                    fontSize: `${
                      settingsSelector.FontSize === "Large"
                        ? "large"
                        : settingsSelector.FontSize === "Extra Large"
                        ? "x-large"
                        : "14px"
                    }`,
                  }}
                >
                  <option value={""}>None Selected</option>
                  {companyContacts &&
                    companyContacts.length > 0 &&
                    companyContacts.map((contact, index) => (
                      <option key={index} value={contact.id}>
                        {contact.email} ({contact.name})
                      </option>
                    ))}
                  <option value={"+"}>+</option>
                </select>
                {checkTo && (
                  <span className={"text-red"}>
                    {t("formValidationMessages.sendEmailTemplates.toRequired")}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type={"text"}
                  placeholder={"Teema"}
                  id={"subject"}
                  name={"subject"}
                  className={"form-control mt-2"}
                  defaultValue={formValues.subject}
                  onChange={(e) =>
                    setFormValues({ ...formValues, subject: e.target.value })
                  }
                  required={true}
                  style={{
                    fontSize: `${
                      settingsSelector.FontSize === "Large"
                        ? "large"
                        : settingsSelector.FontSize === "Extra Large"
                        ? "x-large"
                        : "14px"
                    }`,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type={"file"}
                  id={"files"}
                  name={"files"}
                  className={"form-control mt-2"}
                  multiple={true}
                  onChange={(e) => {
                    setUploadFiles([...uploadFiles, e.target.files]);
                  }}
                  style={{
                    fontSize: `${
                      settingsSelector.FontSize === "Large"
                        ? "large"
                        : settingsSelector.FontSize === "Extra Large"
                        ? "x-large"
                        : "14px"
                    }`,
                  }}
                />
              </FormGroup>
            </div>
            <CKEditor
              activeClass="p10"
              content={body}
              events={{
                // blur: onBlur,
                change: onChange,
              }}
              config={{
                autoParagraph: false,
                removePlugins: "sourcearea,link",
                removeButtons: "Link",
                extraAllowedContent: "a[*]",
              }}
            />
            <div className={"w-100 text-right mt-3"}>
              <Button
                type={"submit"}
                color={"primary"}
                disabled={sendEmailSelector.isLoading}
                style={{
                  fontSize: `${
                    settingsSelector.FontSize === "Large"
                      ? "large"
                      : settingsSelector.FontSize === "Extra Large"
                      ? "x-large"
                      : "14px"
                  }`,
                }}
              >
                {sendEmailSelector.isLoading ? (
                  <Loader />
                ) : (
                  <>{t("buttons.send")}</>
                )}
              </Button>
            </div>
          </fieldset>
        </Form>
        <Toast />
      </>
    );
  };

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
        <Container fluid={true}>
          <Row className="clearfix">
            <Col md={12}>
              <div className={"text-right mb-3 mr-3"}>
                <Button
                  type={"button"}
                  color={"primary"}
                  onClick={() => history.push(`/hr-companies/view/${id}`)}
                  style={{
                    fontSize: `${
                      settingsSelector.FontSize === "Large"
                        ? "large"
                        : settingsSelector.FontSize === "Extra Large"
                        ? "x-large"
                        : "14px"
                    }`,
                  }}
                >
                  {t("buttons.back")}
                </Button>
              </div>
            </Col>
            <Col md={12}>
              <div className="container-fluid">
                <ul className="nav nav-tabs page-header-tab">
                  <li className="nav-item">
                    <NavLink
                      to="#"
                      className="nav-link active"
                      style={{
                        fontSize: `${
                          settingsSelector.FontSize === "Large"
                            ? "large"
                            : settingsSelector.FontSize === "Extra Large"
                            ? "x-large"
                            : "15px"
                        }`,
                      }}
                    >
                      {/* Saada email ettevõttele */}
                      {t("sendEmailsTabs.companyTab")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <div className="summernote">
                    <RenderSendEmailEditor />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SendCompanyLinkEmail;

const RenderAddNewContactModal = ({
  conpamyContactId,
  addNewContactModal,
  setAddNewContactModal,
  toggleAddNewContactModal,
  fetchCompanyContacts,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const companyContactInitialValues = {
    name: "",
    email: "",
    phone: "",
    position: "",
  };

  const [companyContactFormValues, setCompanyContactFormValues] = useState(
    companyContactInitialValues
  );
  const [errors, setErrors] = useState({});

  const companySelector = useSelector((state) => state.companies);

  const formik = useFormik({
    initialValues: companyContactFormValues,
    onSubmit: (value) => {
      let formData = new FormData();
      formData.append("en[contacts][name]", companyContactFormValues.name);
      formData.append("en[contacts][email]", companyContactFormValues.email);
      formData.append("en[contacts][phone]", companyContactFormValues.phone);
      formData.append(
        "en[contacts][position]",
        companyContactFormValues.position
      );
      dispatch(
        addNewCompanyContact(
          conpamyContactId,
          formData,
          setAddNewContactModal,
          fetchCompanyContacts,
          setErrors
        )
      );
    },
  });

  return (
    <Modal isOpen={addNewContactModal} toggle={toggleAddNewContactModal}>
      <ModalHeader>
        {t("companies.sendEmailTemplatesAddMoreContactsModal.heading")}
      </ModalHeader>

      {/*ERRORS*/}
      {Object.keys(errors).length !== 0 ? (
        <FormValidationErrors errors={errors} />
      ) : null}

      <Form onSubmit={formik.handleSubmit}>
        <fieldset disabled={companySelector.isLoading}>
          <ModalBody>
            <FormGroup>
              <Label>
                {t("companies.addNewCompany.personContact.name")}{" "}
                <img src={asterik} height={10} width={10} className="mt-n2" />
              </Label>
              <Input
                type="text"
                className="form-control"
                name="name"
                onChange={(e) =>
                  setCompanyContactFormValues({
                    ...companyContactFormValues,
                    name: e.target.value,
                  })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("companies.addNewCompany.personContact.email")}{" "}
                <img src={asterik} height={10} width={10} className="mt-n2" />
              </Label>
              <Input
                type="email"
                className="form-control"
                name="email"
                onChange={(e) =>
                  setCompanyContactFormValues({
                    ...companyContactFormValues,
                    email: e.target.value,
                  })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("companies.addNewCompany.personContact.phone")}{" "}
                <img src={asterik} height={10} width={10} className="mt-n2" />
              </Label>
              <Input
                type="number"
                className="form-control"
                name="phone"
                onChange={(e) =>
                  setCompanyContactFormValues({
                    ...companyContactFormValues,
                    phone: e.target.value,
                  })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {t("companies.addNewCompany.personContact.position")}
              </Label>
              <Input
                type="text"
                className="form-control"
                name="position"
                onChange={(e) =>
                  setCompanyContactFormValues({
                    ...companyContactFormValues,
                    position: e.target.value,
                  })
                }
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="danger"
              onClick={toggleAddNewContactModal}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={companySelector.isLoading}
            >
              {companySelector.isLoading ? (
                <Loader />
              ) : (
                <>
                  {t(
                    "companies.sendEmailTemplatesAddMoreContactsModal.addContactButton"
                  )}
                </>
              )}
            </Button>
          </ModalFooter>
        </fieldset>
      </Form>
    </Modal>
  );
};
