// export const baseURL = "http://127.0.0.1:8000/";
// export const storageURL = "http://127.0.0.1:8000/storage/";
// export const baseURL = "http://hra-dev.com/";
export const baseURL = "https://api.nbconsult.ee/";
export const storageURL = "https://api.nbconsult.ee/storage/";
// export const baseURL = "https://api.mshr.ee/";
// export const storageURL = "https://api.mshr.ee/storage/";

export const importFromFileBaseURL = "https://ai.nbconsult.ee:4222/";
export const importFromFileNameURL = "https://ai.nbconsult.ee/";
// export const importFromFileBaseURL = "https://ai.mshr.ee:4212/";
// export const importFromFileNameURL = "https://ai.mshr.ee/";
// export const importFromFileBaseURL = "http://127.0.0.5:4000/";
